import React from "react";

import classNames from "classnames";
import brand from "@utils/brand";
import { Container } from "@atoms";
import { Carousel, HeadingWIcon, CardProject } from "@molecules";

const CarouselProjectCards = ({
  color,
  icon,
  heading,
  copy,
  cards,
  small,
  maxVisible,
}) => {
  const { romero } = brand;

  if (cards && cards.length > 0) {
    return (
      <div
        className={classNames("relative w-full overflow-hidden", {
          "bg-gray-light pt-20 pb-24": !romero,
          "py-12 md:py-24": romero,
          "bg-white": color === "white",
        })}
      >
        <Container>
          <div className="relative">
            <HeadingWIcon
              heading={heading}
              copy={copy}
              icon={icon}
              className={classNames({
                "mb-12 lg:mb-16": cards && !romero,
                "mb-8 lg:mb-12": cards && romero,
              })}
              colors={romero ? { heading: "text-blue" } : null}
            />
            {cards.length > 2 && (
              <div className="-mx-3">
                <Carousel
                  showMultiple
                  indicators={false}
                  maxVisible={maxVisible}
                  centerItems
                >
                  {cards.map(card => (
                    <div className="px-3">
                      <div className="group block h-full">
                        <CardProject {...card} small={small} />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
            {cards.length <= 2 && (
              <div className="-mx-3 flex flex-wrap space-y-6 md:space-y-0">
                {cards.map(card => (
                  <div className="w-full px-3 md:w-1/2">
                    <div className="group block">
                      <CardProject {...card} small={small} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

CarouselProjectCards.defaultProps = {
  icon: null,
  small: false,
  maxVisible: 2,
};

export default CarouselProjectCards;
